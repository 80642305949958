module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VRlitic","short_name":"VRlitic","start_url":"/","background_color":"#fff","theme_color":"#4FABDA","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e0e415766a5d30b579a77f2e6afb2532"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://vrlitic.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../packages/gatsby-theme-seomass/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteTitle":"VRlitic","siteDescription":"Compare the top VR Headsets!","siteUrl":"https://vrlitic.com","siteSubject":"VR Headsets","siteSubjectSlug":"vr-headsets","siteLanguage":"en","siteImage":"/banner.jpg","siteTheme":{"mainColor":"#4FABDA","secondColor":"#357391","thirdColor":"#1f4355"},"siteNavigation":[{"name":"VR Headsets","slug":"/vr-headsets/","submenu":"categories"},{"name":"Blog","slug":"/blog/"},{"name":"About","isCustomPage":true,"slug":"/about/","staticSubMenu":[{"name":"Contact us","slug":"/contact/"}]}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-windicss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
